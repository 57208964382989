import { useState } from "react"
import { Link } from "react-router-dom"
import logoThemeDark from "images/logo_theme_dark.png"
import logoThemeLight from "images/logo_theme_light.png"
import { LanguageSelector, ThemeSelector } from "component"

const Header = () => {
  const [theme, setTheme] = useState(localStorage.getItem("theme"))
  return (
    <header>
      <Link to="/">
        <img
          src={theme === "dark" ? logoThemeDark : logoThemeLight}
          alt="Timezoooo"
        />
      </Link>
      <span>
        <LanguageSelector />
        <ThemeSelector theme={theme} setTheme={setTheme} />
      </span>
    </header>
  )
}

export default Header
