import { useState } from "react"
// import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { ReactComponent as Copy } from "images/copy.svg"
import Confetti from "react-confetti"
import "./success.scss"

const Success = ({ link }) => {
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)

  const loc = window.location.host + "/" + link

  return (
    <div id="success-container">
      <Confetti numberOfPieces={500} recycle={false} tweenDuration={1000} />
      <div id="success">
        <span className="success-message" role="alert">
          Event created successfully.
        </span>
        <CopyToClipboard
          text={loc}
          onCopy={() => {
            setCopied(true)
            setTimeout(() => {
              setCopied(false)
            }, 1000)
          }}
        >
          <div className="copy-to-clipboard">
            <span>{loc}</span>
            <span className="indicator">
              {copied ? (
                <span className="copied">{t("create-event.success.copy")}</span>
              ) : (
                <Copy />
              )}
            </span>
          </div>
        </CopyToClipboard>
      </div>
    </div>
  )
}

export default Success
