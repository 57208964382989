import { DateTime } from "luxon"

const replaceFirst = (str) => {
  return "00" + str.substring(2)
}

const formatDate = (x) => x.toFormat("yyyy-LL-dd")
const formatTime = (x) => x.toLocaleString(DateTime.TIME_24_SIMPLE)

const today = DateTime.now().plus({ hours: 1 })
const todayDate = formatDate(today)
const todayTime = formatTime(today).startsWith("24")
  ? replaceFirst(formatTime(today))
  : formatTime(today)

export { formatDate, formatTime, today, todayDate, todayTime }
