import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Success } from "component/Success"
import CreateEventForm from "./CreateEventForm"
// styles
import "./create-event.scss"

// const CurrentTimezone = ({ today }) => {
//   const { t } = useTranslation()

//   return (
//     <div>
//       <p>{t("today.date", { date: today })}</p>
//     </div>
//   )
// }

const CreateEvent = () => {
  const { t } = useTranslation()
  const [success, setSuccess] = useState(false)

  return (
    <div id="create-event-page">
      <div id="create-event-form-container">
        <h1>{t("create-event.heading")}</h1>
        <CreateEventForm success={success} setSuccess={setSuccess} />
      </div>
      {success && <Success link={success} />}
      {/* <CurrentTimezone today={today} /> */}
    </div>
  )
}

export default CreateEvent
