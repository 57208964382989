import clsx from "clsx"
// hooks
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { useFocus } from "utils/hooks"
// components
import { TimezoneSelector } from "component"
// helper
import { db } from "utils/firebase"
import {
  formatDate,
  formatTime,
  today,
  todayDate,
  todayTime,
} from "utils/dateTimeHelper"
// styles
import "./create-event.scss"

const Optional = ({ t }) => (
  <span className="optional">{t("create-event.form.optional")}</span>
)

const CreateEventForm = ({ setSuccess }) => {
  const { t } = useTranslation()
  const [endTimeInputRef, setEndTimeInputRef] = useFocus()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({
    reValidateMode: "onChange",
  })

  // option button controllers
  const [showEndDate, setShowEndDate] = useState(false)
  const [showLink, setShowLink] = useState(false)

  const onSubmit = (data) => {
    let formData = { ...data }
    if (!showLink) {
      delete formData.link
    }

    if (!showEndDate) {
      delete formData.endTime
      delete formData.endDate
      delete formData.endTimezone
    }

    return db
      .collection("events")
      .add(formData)
      .then((d) => {
        setSuccess(d.id)
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        })
      })
      .catch((error) => {
        console.error("Error adding document: ", error)
      })
  }

  return (
    <form id="create-event-form" onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <label htmlFor="startDate">{t("create-event.form.start-time")}</label>
        <div className="date-time-container">
          <input
            {...register("startDate", { required: true })}
            type="date"
            id="startDate"
            name="startDate"
            className={clsx("date", errors["startDate"] ? "error" : "")}
            defaultValue={todayDate}
            disabled={isSubmitting}
          />
          <input
            {...register("startTime", { required: true })}
            type="time"
            id="startTime"
            name="startTime"
            className={clsx("time", errors["startTime"] ? "error" : "")}
            defaultValue={todayTime}
            disabled={isSubmitting}
          />
        </div>
        <TimezoneSelector name="startTimezone" control={control} />
      </fieldset>
      <button
        className="option-button"
        onClick={() => {
          if (!showEndDate) {
            const newEndDate = today.plus({ hours: 1 })
            setValue("endDate", formatDate(newEndDate))
            setValue("endTime", formatTime(newEndDate))
          }
          setShowEndDate((i) => !i)
          if (!showEndDate) {
            setTimeout(() => setEndTimeInputRef(), 200)
          }
        }}
        type="button"
        disabled={isSubmitting}
      >
        {showEndDate
          ? t("create-event.form.question-remove-end-date")
          : t("create-event.form.question-add-end-date")}
      </button>
      {showEndDate && (
        <fieldset>
          <label htmlFor="endTime">
            {t("create-event.form.end-time")}
            <Optional t={t} />
          </label>
          <div className="date-time-container">
            <input
              {...register("endDate")}
              type="date"
              id="endDate"
              name="endDate"
              className={("endDate", clsx(errors["endDate"] ? "error" : ""))}
              defaultValue={todayDate}
              min={todayDate}
              disabled={isSubmitting}
            />
            <input
              {...register("endTime")}
              ref={endTimeInputRef}
              type="time"
              id="endTime"
              name="endTime"
              className={("time", clsx(errors.time ? "error" : ""))}
              defaultValue={todayTime}
              disabled={isSubmitting}
            />
          </div>
        </fieldset>
      )}
      <fieldset>
        <label htmlFor="title">{t("create-event.form.title")}</label>
        <input
          {...register("title", { required: true })}
          type="text"
          id="title"
          name="title"
          className={clsx(errors.title ? "error" : "")}
          disabled={isSubmitting}
        />
      </fieldset>
      <fieldset>
        <label htmlFor="description">
          {t("create-event.form.description")}
          <Optional t={t} />
        </label>
        <textarea
          {...register("description")}
          id="description"
          name="description"
          className={clsx(errors.description ? "error" : "")}
          disabled={isSubmitting}
        />
      </fieldset>
      {!showLink && (
        <button
          className="option-button"
          onClick={() => setShowLink((i) => !i)}
          type="button"
          disabled={isSubmitting}
        >
          {t("create-event.form.question-add-link")}
        </button>
      )}
      {showLink && (
        <fieldset>
          <label htmlFor="link">
            {t("create-event.form.link")}
            <Optional t={t} />
          </label>
          <input
            {...register("link")}
            disabled={isSubmitting}
            type="text"
            id="link"
            name="link"
            className={clsx(errors.link ? "error" : "")}
          />
        </fieldset>
      )}
      <button
        className="submit"
        disabled={isSubmitting}
        form="create-event-form"
        type="submit"
      >
        {t("create-event.form.submit")}
      </button>
    </form>
  )
}

export default CreateEventForm
