import { useCallback, useEffect } from "react"
import { ReactComponent as ThemeMode } from "images/moon.svg"
import "./theme-selector.scss"

const ThemeSelector = (props) => {
  const { theme, setTheme } = props

  const addClass = (t) => {
    document.documentElement.className = ""
    document.documentElement.classList.add(`theme-${t}`)
  }

  const changeTheme = useCallback(
    (t) => {
      localStorage.setItem("theme", t)
      addClass(t)
      setTheme(t)
    },
    [setTheme]
  )

  useEffect(() => {
    if (!theme) {
      changeTheme("dark")
    } else {
      addClass(theme)
    }
  }, [changeTheme, theme])

  return (
    <ThemeMode
      onClick={() => changeTheme(theme === "dark" ? "light" : "dark")}
      className={`${theme}-mode`}
    />
  )
}

export default ThemeSelector
