import { useTranslation } from "react-i18next"

const languages = {
  en: { nativeName: "English" },
  fr: { nativeName: "French" },
  de: { nativeName: "Deutsch" },
  hi: { nativeName: "Hindi" },
}

const LanguageSelector = (props) => {
  const { i18n } = useTranslation()

  return (
    <select
      className="language-select"
      onChange={(e) => i18n.changeLanguage(e.target.value)}
    >
      {Object.keys(languages).map((language) => (
        <option
          key={language}
          style={{
            fontWeight: i18n.language === language ? "bold" : "normal",
          }}
          type="submit"
          value={language}
        >
          {languages[language].nativeName}
        </option>
      ))}
    </select>
  )
}

export default LanguageSelector
