import { Controller } from "react-hook-form"
import TimezoneSelect from "react-timezone-select"
import "./timezone-selector.scss"

const Selector = ({ value, onBlur, onChange, ...rest }) => {
  return (
    <TimezoneSelect
      classNamePrefix="timezone-selector"
      value={value ? value : ""}
      onBlur={onBlur}
      onChange={onChange}
      {...rest}
    />
  )
}

const TimezoneSelector = (props) => {
  const { control, name } = props
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return (
    <div className="timezone-container">
      {control ? (
        <Controller
          control={control}
          defaultValue={currentTimezone}
          name={name}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => <Selector value={value} onBlur={onBlur} onChange={onChange} />}
        />
      ) : (
        <Selector
          value={props.value}
          onBlur={props.onBlur}
          onChange={props.onChange}
        />
      )}
    </div>
  )
}

export default TimezoneSelector
