import { Suspense } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
// layout
import Header from "layout/Header"
import Footer from "layout/Footer"
// pages
import { CreateEvent, NotFound404, ViewEvent } from "pages"

const App = () => {
  return (
    <Router>
      <div id="app-container">
        <Header />
        <main>
          <Switch>
            <Route exact path="/">
              <CreateEvent />
            </Route>
            <Route exact path="/:eventId">
              <ViewEvent />
            </Route>
            <Route path="*">
              <NotFound404 />
            </Route>
          </Switch>
        </main>
        <Footer />
      </div>
    </Router>
  )
}

// here app catches the suspense from page in case translations are not yet loaded
function WrappedApp() {
  // TODO: Add loader
  return (
    <Suspense fallback="Loading">
      <App />
    </Suspense>
  )
}

export default WrappedApp
