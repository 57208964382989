// utils
import { db } from "utils/firebase"
// hooks
import { useTranslation } from "react-i18next"
import { useCollectionOnce } from "react-firebase-hooks/firestore"
import { useParams } from "react-router-dom"
// components
import { NotFound404 } from "pages"
import { TimezoneSelector } from "component"
// styles
import "./view-event.scss"

const Label = ({ children }) => <p className="section-label">{children}</p>
const Section = ({ children, name }) => (
  <section id={name} name={name}>
    {children}
  </section>
)

const ViewEvent = () => {
  const { t } = useTranslation()
  const { eventId } = useParams()
  const query = db.collection("events").doc(eventId)
  const [snapshot, loading, error] = useCollectionOnce(query)

  if (loading) return <p>Loading ...</p>
  if (error) return <p>Error</p>
  if (!snapshot.exists) return <NotFound404 />

  const values = snapshot.data()

  const {
    endDate,
    title,
    description,
    endTime,
    startDate,
    videoLink,
    startTime,
    startTimezone,
  } = values
  return (
    <div id="view-event">
      <h2>{title}</h2>
      <Section name="starts-at">
        <Label>{t("view-event.starts-at")}</Label>
        <div className="date-time-container">
          <input
            type="date"
            id="startDate"
            name="startDate"
            className="date"
            defaultValue={startDate}
            disabled
          />
          <input
            type="time"
            id="startTime"
            name="startTime"
            className="time"
            defaultValue={startTime}
            disabled
          />
        </div>
        {/* <TimezoneSelector name="startTimezone" value={startTimezone} /> */}
      </Section>
      {endTime && (
        <Section name="ends-at">
          <Label>{t("view-event.ends-at")}</Label>
          <div className="date-time-container">
            <input
              autoFocus
              type="date"
              id="endDate"
              name="endDate"
              className="date"
              defaultValue={endDate}
              disabled
            />
            <input
              type="time"
              id="endTime"
              name="endTime"
              className="time"
              defaultValue={endTime}
              disabled
            />
          </div>
          <TimezoneSelector name="startTimezone" value={startTimezone} />
        </Section>
      )}
      {description && (
        <Section name="event-details">
          <Label>{t("view-event.event-details")}</Label>
          <p id="description">{description}</p>
        </Section>
      )}
      {videoLink && (
        <Section name="link">
          <Label>{t("view-event.link")}</Label>
          <a href={videoLink} rel="noopener noreferrer" target="_blank">
            {videoLink}
          </a>
        </Section>
      )}
      <button>{t("view-event.add-to-calendar")}</button>
    </div>
  )
}

export default ViewEvent
